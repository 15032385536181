export  async function getMenuData() {
  return [
    {
      title: 'Home',
      key: 'home',
      url: '/ofd/home',
      icon: 'fa fa-home',
    },
    {
      title: 'Pending Orders',
      key: 'pendingorders',
      url: '/ofd/pendingOrders',
      icon:'fa fa-tasks'
    },
    {
      title:'In Progress Orders',
      key:'inprogress',
      url:'/ofd/inprogressOrders',
      icon:'fa fa-cart-plus'
    },
    {
      title: 'In Transit Orders',
      key: 'intransitorders',
      // url: '/ofd/intransitOrders',
      icon:'fa fa-truck',
      children:[
        {
          title:'Picked Orders',
          url:'/ofd/picked',
          key:'pickedo',
        },
        {
          title:'Assigned Orders',
          url:'/ofd/assigned',
          key:'assigned',
        },
        {
          title:'Out for delivery Orders',
          key:'outfordelivery',
          url:'/ofd/outfordelivery',
          // icon:'fa fa-cart-plus'
        },
      ]
    },
    {
      title:'Not Available Orders',
      key:'notavailable',
      url:'/ofd/notavailable',
      icon:'fa fa-cart-plus'
    },
    {
      title: 'Cancelled Orders',
      key: 'cancelledorders',
      url: '/ofd/cancelledOrders',
      icon:'fa fa-strikethrough'
    },
    {
      title: 'All Orders',
      key: 'allorders',
      url: '/ofd/allOrders',
      icon:'fa fa-th-large'
    },
    {
      title: 'Completed Orders',
      key: 'completedord',
      url: '/ofd/completedord',
      icon:'fa fa-th-large'
    },
    {
      title:'Delivery',
      key:'delivery',
      icon:'fa fa-truck ',
      children:[
        {
          title:'Company',
          url:'/ofd/company',
          key:'company',
        },
        {
          title:'Driver',
          url:'/ofd/driver',
          key:'driver',
        },
        {
        title:'Today Drivers',
        url:'/ofd/todaydrivers',
        key:'todaydriver',
        },
        {
          title:'Cash Collection',
          url:'/ofd/cashcollection',
          key:'cashcollection',
        }
      ]
     
    },
    {
      title: 'Reports',
      key: 'reports',
      // url: '/ofd/reports',
      icon:'fa fa-bar-chart',
      children: [
      {
        title: 'Daily Sales',
        url : '/ofd/dailysales',
        key:'Dashboard'
      },
      {
        title: 'Sales Summary',
        url : '/ofd/salesSummary',
        key:'salesSummarydashboard'
      },
      {
        title:'Payment Logs',
        url:'/ofd/paymentlogs',
        key:'paymentlogs'
      }
    ]
    },
  ]
}

export  async function getMenuDataforManagers() {
  return [
    {
      title: 'Home',
      key: 'home',
      url: '/manager/home',
      icon: 'fa fa-home',
    },
    {
      title: 'Pending Orders',
      key: 'pendgorders',
      url: '/manager/pendingOrders',
      icon:'fa fa-tasks'
    },
    {
      title:'In Progress Orders',
      key:'inprogressord',
      url:'/manager/inprogressOrders',
      icon:'fa fa-cart-plus'
    },
    {
      title: 'In Transit Orders',
      key: 'intransitordss',
      url: '/manager/intransitOrders',
      icon:'fa fa-truck',
      children: [
        {
          title: 'Picked Orders',
          url : '/manager/picked',
          key:'pickedman1'
        },
        {
          title: 'Assigned Orders',
          url : '/manager/assigned',
          key:'assignedman1'
        },
        {
          title:'Out for delivery Orders',
          key:'outfordeliverymana',
          url:'/manager/outfordelivery',
          // icon:'fa fa-cart-plus'
        },
      ]
    },
    {
      title:'Not Available Orders',
      key:'notavailablemana',
      url:'/manager/notavailable',
      icon:'fa fa-cart-plus'
    },
    {
      title: 'Cancelled Orders',
      key: 'cancelledorders',
      url: '/manager/cancelledOrders',
      icon:'fa fa-strikethrough'
    },
    {
      title: 'All Orders',
      key: 'allordersss',
      url: '/manager/allOrders',
      icon:'fa fa-th-large'
    },
    {
      title: 'Completed Orders',
      key: 'completedordmana',
      url: '/manager/completedord',
      icon:'fa fa-th-large'
    },
    {
      title: 'Reports',
      key: 'reports',
      // url: '/manager/reports',
      icon:'fa fa-bar-chart',
      children: [
        {
          title: 'Daily Sales',
          url : '/manager/reports/dailysales',
          key:'Dashboard1'
        },
        {
          title: 'Sales Summary',
          url : '/manager/reports/salesSummary',
          key:'salesSummarydashboard1'
        },
	{
        title:'Payment Logs',
        url:'/ofd/paymentlogs',
        key:'paymentlogs'
        }
      ]
    },
  ]
}
