import axios from 'axios'
import { notification } from 'antd'
import Url from '../pages/config'

// custom login functionality

export async function login(email,password) {
  window.localStorage.setItem('flag', false);
  window.localStorage.setItem('email','');
  let flag = false;
  console.log("for login form data===>", email, password)
  // const Email = email
  const pass = password
  const loginUrl = `${Url.URL}webLogin`
  await axios({
    method: 'GET',
    url: loginUrl,
    headers: {
      'Access-Control-Allow-Headers': '*',
      username: email,
      password: pass,
    },
  }).then((response) => {
    console.log(response,"response on login=============>",response)
    const roleid = response.data.roleId;
    const  Role = response.data.role;
      const shopId= response.data.shops
    const Name = response.data.name
    // console.log("NameshopId================>",Name,shopId)
    if (response.data.status === 200) {
      flag = true;
      window.localStorage.setItem('flag', true);
      window.localStorage.setItem('name',Name)
      window.localStorage.setItem('email',email);
      window.localStorage.setItem('roleid',roleid)
      window.localStorage.setItem('role',Role)
      window.localStorage.setItem('shopid',shopId)
    } else {
      console.log("inside else")
      notification.warning({
        message: "Error",
        description: "Invalid User",
      })
    }
  })
  return flag;
}

  export async function currentAccount() {
    const obj = {
      email:window.localStorage.getItem('email'),
      authorized:window.localStorage.getItem('flag'),
      roleid:window.localStorage.getItem('roleid'),
      role:window.localStorage.getItem('role'),
      name:window.localStorage.getItem('name'),
      shopid: window.localStorage.getItem('shopid')
    }
    return obj;
  }

export async function logout() {
  window.localStorage.removeItem('flag');
  window.localStorage.removeItem('roleid');
  window.localStorage.removeItem('role');
  window.localStorage.removeItem('email');
  window.localStorage.removeItem('name')
  window.localStorage.removeItem('shopid')
  return true
}




