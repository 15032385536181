import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Switch from 'react-router-transition-switch'
import Loadable from 'react-loadable'
import { connect } from 'react-redux'

import Layout from 'layouts'
import Loader from 'components/layout/Loader'
import NotFoundPage from 'pages/system/404'

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Dashboards
  {
    path: '/ofd/home',
    Component: loadable(() => import('pages/ofd/home')),
    exact: true,
  },
  {
    path: '/ofd/allOrders',
    Component: loadable(() => import('pages/ofd/allOrders')),
    exact: true,
  },
  // {
  //   path: '/ofd/reports',
  //   Component: loadable(() => import('pages/ofd/reports')),
  //   exact: true,
  // },
  {
    path:'/ofd/dailysales',
    Component: loadable(() => import('pages/ofd/reports/dailysales')),
    exact: true,
  },
  {
    path:'/ofd/salesSummary',
    Component: loadable(() => import('pages/ofd/reports/salesSummary')),
    exact: true,
  },
  
  // System Pages
  {
    path: '/system/login',
    Component: loadable(() => import('pages/system/login')),
    exact: true,
  },
  {
    path: '/system/forgot-password',
    Component: loadable(() => import('pages/system/forgot-password')),
    exact: true,
  },
  {
    path: '/system/register',
    Component: loadable(() => import('pages/system/register')),
    exact: true,
  },
  {
    path: '/system/lockscreen',
    Component: loadable(() => import('pages/system/lockscreen')),
    exact: true,
  },
  {
    path: '/system/404',
    Component: loadable(() => import('pages/system/404')),
    exact: true,
  },
  {
    path: '/system/500',
    Component: loadable(() => import('pages/system/500')),
    exact: true,
  },
  {
    path:'/ofd/inprogressOrders',
    Component: loadable(() => import('pages/ofd/inprogressOrders')),
    exact: true,
  },
  {
    path:'/ofd/pendingOrders',
    Component: loadable(() => import('pages/ofd/pendingOrders')),
    exact: true,
  },
  {
    path:'/ofd/cancelledOrders',
    Component: loadable(() => import('pages/ofd/cancelledOrders')),
    exact: true,
  },
  {
    path:'/ofd/viewOrderdetails',
    Component: loadable(() => import('pages/ofd/viewOrderdetails')),
    exact: true,
  },
  {
    path:'/ofd/paymentlogs',
    Component: loadable(() => import('pages/ofd/paymentlogs')),
    exact:true,
  },
  {
    path:'/ofd/notavailable',
    Component: loadable(() => import('pages/ofd/notavailable')),
    exact:true,
  },
  {
    path:'/ofd/completedord',
    Component: loadable(() => import('pages/ofd/completedorders')),
    exact:true,
  },
  // Delivery windows started from here //

  {
    path: '/ofd/company',
    Component: loadable(() => import('pages/ofd/delivery/company')),
    exact: true,
  },
  {
    path: '/ofd/driver',
    Component: loadable(() => import('pages/ofd/delivery/driver')),
    exact: true,
  },
  {
    path: '/ofd/todaydrivers',
    Component: loadable(() => import('pages/ofd/delivery/todaydrivers')),
    exact: true,
  },
  {
    path: '/ofd/cashcollection',
    Component: loadable(() => import('pages/ofd/delivery/cashcollection')),
    exact: true,
  },
  {
    path: '/ofd/picked',
    Component: loadable(() => import('pages/ofd/intransitOrders/pickedOrders')),
    exact: true,
  },
  {
    path: '/ofd/assigned',
    Component: loadable(() => import('pages/ofd/intransitOrders/assignedOrders')),
    exact: true,
  },
  {
    path:'/ofd/outfordelivery',
    Component: loadable(() => import('pages/ofd/intransitOrders/outfordelivery')),
    exact:true,
  },
  // widows for managers
  {
    path:'/manager/home',
    Component: loadable(() => import('pages/manager/home')),
    exact: true,
  },
  {
    path:'/manager/allOrders',
    Component: loadable(() => import('pages/manager/allOrders')),
    exact: true,
  },
  {
    path:'/manager/inprogressOrders',
    Component: loadable(() => import('pages/manager/inprogressOrders')),
    exact: true,
  },
  
  {
    path:'/manager/picked',
    Component: loadable(() => import('pages/manager/intransitOrders/pickedOrders')),
    exact: true,
  },
  {
    path:'/manager/assigned',
    Component: loadable(() => import('pages/manager/intransitOrders/assignedOrders')),
    exact: true,
  },
  {
    path:'/manager/outfordelivery',
    Component: loadable(() => import('pages/manager/intransitOrders/outfordelivery')),
    exact:true,
  },
  {
    path:'/manager/pendingOrders',
    Component: loadable(() => import('pages/manager/pendingOrders')),
    exact: true,
  },
  {
    path:'/manager/viewOrderdetails',
    Component: loadable(() => import('pages/manager/viewOrderdetails')),
    exact: true,
  },
  // {
  //   path:'/manager/reports',
  //   Component: loadable(() => import('pages/manager/reports')),
  //   exact: true,
  // },
  {
    path:'/manager/cancelledOrders',
    Component: loadable(() => import('pages/manager/cancelledOrders')),
    exact: true,
  },
  {
    path:'/manager/reports/dailysales',
    Component: loadable(() => import('pages/manager/reports/dailysales')),
    exact: true,
  },
  {
    path:'/manager/reports/salesSummary',
    Component: loadable(() => import('pages/manager/reports/salesSummary')),
    exact: true,
  },
  {
    path:'/manager/notavailable',
    Component: loadable(() => import('pages/manager/notavailable')),
    exact:true,
  },
  {
    path:'/manager/completedord',
    Component: loadable(() => import('pages/manager/completedorders')),
    exact:true,
  },

]
const mapStateToProps = ({ settings,user }) => ({ settings,user })

@connect(mapStateToProps)
class Router extends React.Component {
  render() {
    const {
      history,
      settings: { routerAnimation },
      user
    } = this.props
    console.log("user in router.js file====>",user)
    const usernameRole =user.name
    return (
      <ConnectedRouter history={history}>
        <Layout>
          <Switch
            render={props => {
              const {
                children,
                location: { pathname },
              } = props
              return (
                <SwitchTransition>
                  <CSSTransition
                    key={pathname}
                    classNames={routerAnimation}
                    timeout={routerAnimation === 'none' ? 0 : 300}
                  >
                    {children}
                  </CSSTransition>
                </SwitchTransition>
              )
            }}
          >
            <Route exact path="/" render={() => usernameRole === 'Administrator' ? <Redirect to="/ofd/home" /> : <Redirect to="/manager/home" />} />
            {routes.map(({ path, Component, exact }) => (
              <Route path={path} key={path} exact={exact}>
                <Component />
              </Route>
            ))}
            <Route component={NotFoundPage} />
          </Switch>
        </Layout>
      </ConnectedRouter>
    )
  }
}

export default Router
